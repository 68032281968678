import React from "react"
import Layout from "../../components/layout"
import RecommendationTemplateComponent from "../../components/templates/recommendation";
import {graphql} from "gatsby";
import {isTest} from "../../utilities";

const embedded = true;
const RecommendationTemplate = function ({ data }) {
  const recommendation = data.strapiRecommendation;
  return (
    <Layout embedded={embedded}>
      <RecommendationTemplateComponent embedded={embedded} recommendation={recommendation} webViewVersion="v1" isTest={isTest()} storeImages={{appStoreImage: null, playStoreImage: null, appScreenShotImage: null}} />
    </Layout>
)}

export default RecommendationTemplate

export const query = graphql`
  query EmbeddedRecommendationTemplate($Item: String!) {
    strapiRecommendation(Item: { eq: $Item }) {
      Item
      Body
      Heading1
      Content1
      Heading2
      Content2
      Heading3
      Content3
      Products {
        id
        Title
        Url
        Image_url
        Amazon_tracking_img
        Public
      }
      Resources {
        id
        Title
        Url
        Public
      }
      checklists {
        Title
      }
    }
  }
`